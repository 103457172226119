import React from 'react';

import afsthumbnail from './thumbnails/afs.jpeg';
import tfuandrthumbnail from './thumbnails/tfuandr.jpg';

import styles from './Books.module.css';

const books = [
// {
//   image: tfuandrthumbnail,
//   title: 'Terraform: Up & Running, 2nd Edition',
//   url: 'https://www.oreilly.com/library/view/terraform-up/9781492046899/',
//   rounded: false
// },
{
  image: afsthumbnail,
  title: 'Architecting for Scale, 2nd Edition',
  url: 'https://www.oreilly.com/library/view/architecting-for-scale/9781492057161/',
  rounded: false
}];

const BookThumbnail = ({ image, label, rounded }) => (
  <img src={image} alt={label} title={label} className={`${styles.book} ${rounded ? styles.isRounded : ''}`} />
);

const Book = ({ image, title, url, rounded = true }) => (
  <div className={`col-auto ${styles.bookThumbnailWrapper}`}>
    <a href={url} target="__blank">
      <BookThumbnail rounded={rounded} image={image} label={title} />
    </a>
  </div>
);

const Books = () => {
  const bookComps = books.map((book, index) => (<Book key={index} {...book} />));
  return (
    <div className="row" title="Recently purchased a subscription to OReilly" >
      {
        bookComps
      }
    </div>
  )
}

export default Books;
