import React from 'react';

import Jengen from '../assets/JengenIcon.svg';
import Pairfonts from '../assets/PairfontsIcon.svg';
import Surebro from '../assets/SurebroIcon.svg';

import './sideProjects.css';

const SideProjects = () => (
  <div className="side-projects-wrapper">
    <div className="side-projects">
      <SideProject key="0" name="Surebro" Img={Surebro} url="https://surebro.com" description="A web development studio. It's pretty cool" />
      <SideProject key="1" name="Pairfonts" Img={Pairfonts} url="https://pairfonts.com" description="A web app to pair the perfect fonts" />
      <SideProject key="2" name="Jengen" Img={Jengen} url="https://github.com/chalamphong/jengen" description="A scaffolding cli npm package" />
    </div>
  </div>
);

// const SideProject = ({ name, url }) => (
//   <li className="side-project">
//     <a
//       href={url}
//       className="side-project-link"
//       title={name}
//       target="_blank"
//       rel="noopener noreferrer"
//     >
//       {name}
//     </a>
//   </li>
// );

const SideProject = ({ name, url, description, Img }) => (
  <a className="side-project" href={url} target="__blank">
    {Img && (
      <div className="side-project-icon">{<Img className="side-project-img" width="36" />}</div>
    )}
    <div className="side-project-info">
      <p className="side-project-name">{name}</p>
      <p className="side-project-description">{description}</p>
    </div>
  </a>
);

export default SideProjects;