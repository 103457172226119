import PropTypes from 'prop-types'
import React from 'react'

const Header = ({ className, children }) => (
  <h1 className={`text ${className}`}>
    {children}
  </h1>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
  className: '',
  subtitleClassName: '',
}

export default Header
