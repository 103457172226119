import React from 'react';

import './alert.css';

const Alert = () => (
  <div className="alert ">
    <p>
      Interested to work together on something? Let's chat
    </p>
    <a className="btn" href="mailto:me@chalamphong.com">
      me@chalamphong.com
    </a>
  </div>
);

export default Alert;
