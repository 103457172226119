import React from 'react';

import { ThemeProvider } from '../utils/theme';

const App = ({ children }) => (
  <ThemeProvider>
    {children}
  </ThemeProvider>
);

export default App;
