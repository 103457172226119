import React, { useState, useEffect, useContext } from 'react';
import ModeManager from './mode';

const ThemeContext = React.createContext({ theme: ModeManager.getMode() });

export function ThemeProvider({ children }) {
  const [theme, setTheme] = useState(ModeManager.getMode());
  const toggleTheme = () => {
    ModeManager.toggleMode();
  };

  useEffect(() => {
    global.window.__onThemeChange = setTheme;
  }, []);

  return (
    <ThemeContext.Provider value={{ theme, toggleTheme }}>
      {children}
    </ThemeContext.Provider>
  );
}

export const useTheme = () => useContext(ThemeContext);
