const DARK_MODE = 'dark';
const LIGHT_MODE = 'light';
const MODE_KEY = 'theme';
let DEFAULT_MODE = LIGHT_MODE;
// if (typeof window !== 'undefined' && window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
//   DEFAULT_MODE = DARK_MODE;
// }

const getMode = () => {
//   let mode = DEFAULT_MODE;
//   let darkQuery = {matches: DEFAULT_MODE === DARK_MODE};
//   let preferredTheme = DEFAULT_MODE; 
//   if (typeof window !== 'undefined' && typeof localStorage !== 'undefined') {
//     darkQuery = window.matchMedia("(prefers-color-scheme: dark)");
//     preferredTheme = JSON.parse(localStorage.getItem(MODE_KEY)); 
//   }   
//   mode = preferredTheme || (darkQuery.matches ? DARK_MODE : LIGHT_MODE);
//   console.log('Get mode returning', mode);
//   return mode;
  return LIGHT_MODE;
};

const getFutureMode = (mode) => {
  const newMode = mode === DARK_MODE ? LIGHT_MODE : DARK_MODE;
  return newMode;
}

const setMode = (newMode) => {
  let mode = [DARK_MODE, LIGHT_MODE].includes(newMode) ? newMode : DEFAULT_MODE;
  if (typeof window !== 'undefined') { 
    global.window.__setPreferredTheme(mode);
  }  
  return mode;
};

const toggleMode = () => {
  let mode = getMode();
  const newMode = getFutureMode(mode);
  setMode(newMode);

  return newMode;
}

export default {
  getMode,
  setMode,
  toggleMode,
  getFutureMode,
};