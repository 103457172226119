import React from 'react';

import './socialLinks.css';

const SocialLinks = ({ className }) => (
  <div className={`social-links-wrapper ${className}`}>
    <ul className="social-links">
      <SocialLink key="mail" url="mailto:me@chalamphong.com" title="Email" icon="email" />      
      <SocialLink key="github" url="https://github.com/chalamphong" title="Github" icon="github" /> 
      {/* <SocialLink key="producthunt" url="https://www.producthunt.com/@chalamphongpandey" title="Product Hunt" icon="product-hunt" />
      <SocialLink key="angelList" url="https://angel.co/chalamphong-pandey" title="Angel List" icon="angel-list" />       */}
      <SocialLink key="medium" url="https://medium.com/@chalamphong" title="Medium" icon="medium" />      
      <SocialLink key="stackoverlow" url="https://stackoverflow.com/users/story/1799328?view=Timeline" title="Stackoverflow" icon="stack-overflow" />
    </ul>
  </div>
);

const SocialLink = ({url, title, icon}) => (
  <li className="social-link-item">
    <a className="social-link" href={url} target="_blank" title={title} rel="noopener noreferrer">
      <span className={`social-link-icon icon-${icon}`} />
    </a>
  </li>
);

SocialLinks.defaultProps = {
  className: '',
};

export default SocialLinks;
