import React, { useState } from 'react';

import App from '../containers/app';
import Layout from '../components/layout';
import SEO from '../components/seo';
import Header from '../components/header';
import Body from '../components/body';
import SocialLinks from '../components/socialLinks';
import Projects from '../components/sideProjects';
import Alert from '../components/alert';
import Books from '../components/Books';
import ModeSwitcher from '../components/ModeSwitcher';

import { useTheme } from '../utils/theme';
import ModeManager from '../utils/mode';

const genClass = (className, mode) => `${className} ${mode}`;

const Gradient = ({ mode }) => {
  return (<div className={genClass('gradient', mode)} />);
};

const Keyword = ({ keyword }) => <><u style={{marginRight: '0.4rem'}}>{keyword}</u> </>;

const keywords = ['NodeJS', 'ReactJs', 'Docker', 'MongoDB', 'PostgreSQL', 'Redis', 'AWS DynamoDB', 'Kubernetes', 'AWS Lambda', 'Microservice', 'Serverless'];

const TechStory = () => <>
  <Body key="1" className="purple lighter mt-10">
    <u>NodeJS</u> is my tool of choice. I'll probably containerize your service built with <u>NestJS</u> using <u>Docker</u>. To make sure your services are production ready, I'll orchestrate your containers using <u>Kubernetes</u>. Your frontend will be built on <u>ReactJs</u> and probably delivered to your cliets as static files via a CDN.
  </Body>
  <Body key="2" className="purple lighter mt-10">
    Your data will be stored in <u>MongoDB</u> or <u>PostgreSQL</u> and if all goes well, I'll probably need to add a <u>Redis</u> caching layer to keep the customers happy. Don't want to manage your servers you say? I'll build your service on <u>AWS Lambda</u> and use <u>DynamoDB</u> to store your data.
  </Body>              
  <Body key="4" className="purple lighter mt-10">
    Other things I couldn't incorporate into a sentence, <u>RabbitMQ</u>, <u>AWS SQS</u>, <u>Microservice Architecture</u>, <u>Serverless</u>
  </Body>
  <Body key="3" className="purple lighter mt-10">
    By the way, I can mockup some stuff for you if thats your style using <u>Sketch</u>
  </Body>
</>;

const IndexPage = () => {
  const {theme, toggleTheme} = useTheme();

  return (
    <App>
      <Layout mode={theme}>
        {/* <ModeSwitcher mode={theme} title={`Switch theme to ${ModeManager.getFutureMode(theme)}`} toggleMode={toggleTheme} /> */}
        <SEO title="Chalamphong" keywords={[`web developer`, `software engineer`, `portfolio`, `Bangkok`]} className={theme} />
        <Gradient mode={theme} />
        <div className="container content-wrapper">
          <div className="row">
            <div className="col mb-0">
              <Header
                className="purple strong high-horse mb-0"
              >
                Hey, I’m Chalamphong. I'm a Software Engineer, Problem Solver & a <em>certified*</em> Ping-Pong Master
              </Header>
            </div>            
          </div>
          <div className="row">
            <SocialLinks />
          </div>
          <div className="row">
            <div className="col mb-0">
              <Body key="1" className="purple lighter">
                I enjoy building <span title="talking about five nines. That stuff gets complex fast">robust and reliable systems and platforms</span>. Currently I'm the tech lead at Bizcuit where we build world class B2B SaaS applications <span title="600k transaction per minute at peak">used by a whole bunch of people</span>.
                <br /><span title="This is the 'My Hobbies' part of the intro">I enjoy playing Ping Pong, kettlebells and plants 🌱</span>
              </Body>
            </div>
          </div>
          <div className="row">
            <Alert />
          </div>
          <div className="row">
            <div className="col col-12 col-md-6">
              <h5 className="text light-purple uppercase">
                <span role="img" aria-label="Startup">🏠</span> If you are a startup
              </h5>
              <Body key="1" className="purple lighter mt-10">
                I can help build your MVP fast & set up a strong foundation to ensure your app can scale along with your company
              </Body>
            </div> 
            <div className="col col-12 col-md-6">
              <h5 className="text light-purple uppercase">
                <span role="img" aria-label="Business">🏢</span> If you are a Enterprise
              </h5>
              <Body key="1" className="purple lighter mt-10">
                I can handle the non-essential but crucial aspects of your business so your in-house team can focus on the important stuff
              </Body>
            </div>          
          </div>
          <div className="row">
            <div className="col col-12 col-md-6">
              <h5 className="text light-purple uppercase">
                <span role="img" aria-label="Work">🦚</span> Noteworthy Projects
              </h5>
              <Projects />
            </div>
            <div className="col col-12 col-md-6">
              <h5 className="text light-purple uppercase">
                <span role="img" aria-label="Work">👓</span> Currently Reading
              </h5>
              <Books />
            </div>
          </div>
          <div className="row">     
            <div className="col col-12">
              <h5 className="text light-purple uppercase">
                <span role="img" aria-label="Tech Keywords">🗝</span> Tech Stack Keywords
              </h5>
              <Body className="text purple">
                {
                  keywords.map((keyword, index) => <Keyword key={index} keyword={keyword} />)
                }
              </Body>              
            </div>         
          </div>
          <div className="row">     
            <div className="col col-12">
              <small>
                <p key="1" className="text is-italic is-size-7">
                  <small><em>* Ping-Pong Master certification provided by me to me </em>👍</small> 
                </p>
              </small>              
            </div> 
          </div>
        </div>              
      </Layout>      
    </App>    
  )
}
export default IndexPage
